// import Popper from 'popper.js';
// window.Popper = Popper;
//
// import './bootstrap';

import { Fancybox } from "@fancyapps/ui";


$(document).ready(function() {

    Fancybox.bind("[data-fancybox]");
        $('[name=user_phone]').mask('+38(000) 000-00-00');

    $('.philosophy li').click(function (e) {
        let activeTab = $(this).attr('data-tab');
        e.preventDefault();

        $('.philosophy li').removeClass('active');
        $(this).addClass('active');


        $('.philosophy .philosophy-content .tabs').addClass('d-none')
        $('.philosophy .philosophy-content').find('.' + activeTab).removeClass('d-none');

    });

    var startTime = Math.floor(Date.now() / 1000); // Текущее время в секундах

    $('.feedbackForm').submit(function (e) {
        e.preventDefault();

        var $form = $(this);
        var formData = $form.serializeArray();

        formData.push({ name: 'honeypot', type: 'hidden', value: '' });
        formData.push({ name: 'start_time', type: 'hidden', value: startTime });

        var sessionToken = $form.find('input[name="token"]').val();
        formData.push({ name: 'token', type: 'hidden', value: sessionToken });

        $.ajax({
            url: '/api/sendmail',
            type: 'post',
            data: $.param(formData),
            success: function (response) {
                $form.find('.success-message').removeClass('d-none');
                $form[0].reset();

                setTimeout(function () {
                    $form.find('.success-message').addClass('d-none');

                    var $modal = $form.closest('.modal');
                    if ($modal.length) {
                        $modal.modal('hide');
                    }
                }, 3000);
            },
            error: function (error) {
                console.error('Error sending mail: ', error);
            }
        });
    });
});
